@mixin devseer-search-bar-theme($theme) {

    $background: map-get($theme, background);

    .devseer-search-bar {

        &.expanded {
            background-color: map-get($background, background);
        }
    }
}
