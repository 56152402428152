@mixin dc-search($theme) {

  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  app-dc-search {

    .search-wrapper {
      background: map-get($background, card);

      .search {

        .mat-icon {
          color: map-get($foreground, icon);
        }

        input {
          background: map-get($background, card);
          color: map-get($foreground, text);
        }
      }

    }
  }
}
