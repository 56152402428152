@mixin devseer-widget-theme($theme) {

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    devseer-widget {

        > .devseer-widget-front,
        > .devseer-widget-back {
            background: map-get($background, card);
            border-color: map-get($foreground, divider);
        }
    }
}
