@mixin dc-datatable($theme) {

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $is-dark: map-get($theme, is-dark);

    #app-dc-datatable {


        .table-scroll th,
        .table-scroll td {
                background: map-get($background, card);
        }

        .table-scroll thead th {
                border-color: map-get($foreground, divider);
            }
        .dt-footer{
            border-top:solid 1px map-get($foreground, divider);
        }
        .sort-icon-set {
           color: map-get($foreground, divider);


        }


    }
}