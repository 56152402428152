@mixin devseer-sidebar-theme($theme) {

    $background: map-get($theme, background);

    devseer-sidebar {
        background: map-get($background, background);
    }

    .devseer-sidebar-overlay {
        background-color: rgba(0, 0, 0, 0.6);

        &.devseer-sidebar-overlay-invisible {
            background-color: transparent;
        }
    }
}
