@mixin devseer-countdown-theme($theme) {

    $foreground: map-get($theme, foreground);

    devseer-countdown {

        .devseer-countdown {

            .time {

                .title {
                    color: map-get($foreground, secondary-text);
                }
            }
        }
    }
}
