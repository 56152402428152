@mixin user-theme($theme) {

  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $accent: map-get($theme, accent);
  $is-dark: map-get($theme, is-dark);


  app-user {


    .drawer-item {
      background: mat-color($devseer-navy, 500);

      .secondary-text {
        color: #ececec;
      }

    }

    .drawer-items:hover {
      background: mat-color($devseer-navy, 500);
      color: #ffffff;

      .secondary-text {
        color: #ececec;
      }
    }


    .icon-color {
      color: #ffffff;
    }


    .info-alert {
      background: mat-color($devseer-shady, 800);
      color: mat-color($devseer-shady, 500);
      border: solid 1.2px mat-color($devseer-shady, 300);

    }

    .check-icon {
      color: mat-color($devseer-shady, 500);

    }

    .active-plan {
      border: solid 2px mat-color($devseer-shady, 500);
    }


  }

}

