// -----------------------------------------------------------------------------------------------------
// @ Theming
// -----------------------------------------------------------------------------------------------------
@mixin pagination-controls-theme($theme) {

  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $accent: map-get($theme, accent);
  $is-dark: map-get($theme, is-dark);

  pagination-controls {

    .ngx-pagination {

      background: map-get($background, card);
    }

    .ngx-pagination a,
    .ngx-pagination button {
      color: map-get($foreground, text);

      //@if ($is-dark) {
      //    background: mat-color($developercase-navy, 600);
      //} @else {
      //    background: mat-color($developercase-navy, 50);
      //
      //}

    }


    .ngx-pagination a:hover,
    .ngx-pagination button:hover {
      background: map-get($background, card);
      //background: #000;
      color: mat-color($accent, default);
    }

    .ngx-pagination .current {
      background: mat-color($accent, default);
      color: #fff;

    }

    .ngx-pagination .disabled {
      color: map-get($foreground, secondary-text);
    }

    .ngx-pagination .pagination-next a::after {

      background: #00acc1;
      color: #fff;
    }

    .ngx-pagination .pagination-previous a::before {

      background: #00acc1;
      color: #fff;
    }

    .ngx-pagination .pagination-next a::after,
    .ngx-pagination .pagination-next.disabled::after {
      background: #b1b1b1;
      color: #fff;

    }

    .ngx-pagination .pagination-previous a::before,
    .ngx-pagination .pagination-previous.disabled::before {
      background: #b1b1b1;
      color: #fff;

    }

  }
}

.ngx-pagination {
  margin-left: 0;
  margin-bottom: 1rem;
  padding-left: 0;
  border-radius: 8px;
  display: flex;
}

.ngx-pagination::before, .ngx-pagination::after {
  content: '';
  display: table;
}

//ul li a:last-of-type{
//    background: #1E2129;
//}

.ngx-pagination::after {
  clear: both;
}

.ngx-pagination li {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  margin: 0 6px !important;
}

.ngx-pagination li:first-child {
  margin: 0 !important;
  padding: 0 !important;
}

.ngx-pagination li:last-child {
  margin: 0 !important;
  padding: 0 !important;
}

.ngx-pagination li a:last-child {
  //margin: 0 !important;
  //padding: 0 !important;
}

.ngx-pagination li {
  display: inline-block;
}

//.ngx-pagination li a:last-child {
//    margin: 0 !important;
//    padding: 0 !important;
//}

//.ngx-pagination a,
//.ngx-pagination button {
//
//    display: block;
//    background: #bfbfbf;
//    padding: 6px 12px !important;
//    border-radius: 8px !important;
//
//}

.ngx-pagination a:hover,
.ngx-pagination button:hover {
  //background: #e6e6e6;
  text-decoration: none !important;
}

.ngx-pagination .current {
  cursor: default;
  border-radius: 9px;
  padding: 0.6475rem 1.3rem !important;
  //padding: 6px 12px !important;
}

.ngx-pagination .disabled {
  padding: 0.1875rem 0.625rem;
  cursor: default;
}

.ngx-pagination .disabled:hover {
  background: transparent;
}

.ngx-pagination a, .ngx-pagination button {
  cursor: pointer;
}

.ngx-pagination .pagination-previous a::before,
.ngx-pagination .pagination-previous.disabled::before {
  font-family: 'Material Icons' !important;
  content: "\e408" !important;
  -webkit-font-feature-settings: 'liga';
  display: inline-block;
  margin: 0 !important;
  border-radius: 9px;
  padding: 6px 9px 7px 9px !important;
}

.ngx-pagination .pagination-next a::after,
.ngx-pagination .pagination-next.disabled::after {
  font-family: 'Material Icons' !important;
  content: "\e409" !important;
  -webkit-font-feature-settings: 'liga';
  display: inline-block;
  margin: 0 !important;
  border-radius: 9px;
  padding: 6px 9px 7px 9px !important;
}


.ngx-pagination .show-for-sr {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
}

.ngx-pagination .small-screen {
  display: none;
}

@media screen and (max-width: 601px) {
  .ngx-pagination.responsive .small-screen {
    display: inline-block;
  }
  .ngx-pagination.responsive li:not(.small-screen):not(.pagination-previous):not(.pagination-next) {
    display: none;
  }
}
